<template>
  <moe-page title="资源包更新">
    <moe-form ref="paramsForm" :model="params" :rules="rules" :showBack="false">
      <el-form-item label="资源包类型" prop="type">
        <el-select
          v-model="params.type"
          placeholder="选择资源包类型">
          <el-option
            v-for="(item, index) in assetTypeList"
            :key="index"
            :label="item.label"
            :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="zip压缩包" prop="url">
        <moe-upload-file
          v-model="params.url"
          ossKey="ASSET"
          :default-file-list="urlList"
          :suffix="['zip']"
          :getAndroidApkMd5="true"
          :md5.sync="params.md5"
          :size="100"
          @change="$refs.paramsForm.validateField('url')">
        </moe-upload-file>
      </el-form-item>
      <el-form-item label="压缩包MD5值" prop="md5">
        <el-input v-model="params.md5" placeholder="请输入压缩包MD5值" clearable disabled/>
      </el-form-item>
      <template slot="tool">
        <el-button
          type="primary"
          icon="el-icon-upload2"
          :loading="paramsLoad"
          @click="submit">
          {{ paramsLoad ? '提交中' : '提交信息' }}
        </el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'UpdateAsset',
  data() {
    let params = {
      type: '',       //资源包类型
      url: '',        //资源包下载路径
      md5: '',        //资源包MD5值
    }
    //数据校验
    const verify = this.$moe_verify.verifyForm;
    return {
      params,
      urlList: [],
      assetTypeList: [],
      rules: {
        type: verify.isEmpty('请选择资源包类型'),
        url: verify.isEmpty('请上传资源包下载路径'),
        md5: verify.isEmpty('请上传资源包MD5值')
      },
      paramsLoad: false,
    }
  },
  methods: {
    submit() {
      this.$refs['paramsForm'].validate(() => {
        this.paramsLoad = true;
        this.$moe_api.VERSION_API.versionAsset(this.params).then((data) => {
          if (data.code == 200) {
            this.$refs['paramsForm'].resetFields();
            this.urlList.splice(0);
            this.$moe_msg.success('添加成功');
          } else {
            this.$moe_msg.error(data.message);
          }
        }).finally(() => {
          this.paramsLoad = false
        })
      })
    }
  },
  mounted() {
    this.$moe_api.VERSION_API.getAssetTypes().then((data) => {
      if (data.code === 200) {
        this.assetTypeList = data.result;
      } else {
        this.$moe_msg.error(data.message);
      }
    })
  },
}
</script>